<template>
  <Scrollbar>
    <div class="w-full">
      <h1 class="m-4 school-title text-xl font-semibold text-blue-800">模块管理-编辑模块</h1>
      <div class="m-4">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="模块名称">
            <el-input v-model="formData.title"></el-input>
            <p>该名称用于区分不同模型</p>
          </el-form-item>
          <el-form-item label="模块别名">
            <el-input v-model="formData.another"></el-input>
            <p>该名称用于在报告中显示</p>
          </el-form-item>
          <el-form-item label="模块序号">
            <el-input-number :min="0" v-model="formData.index"></el-input-number>
          </el-form-item>
          <el-form-item label="模块功能">
            <Regular v-model="formData.content" :initial="handleInitial"></Regular>
            <el-button @click="handleAddItem">+添加项</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即保存</el-button>
            <el-button @click="$cloud.go('/pages/model/model')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </Scrollbar>
</template>
<script type="text/javascript">
import Regular from './components/regular'

export default {
  name: "medal",
  components: {
    Regular
  },
  data() {
    return {
      formData: {
        index: 0,
        title: '',
        initial: 1,
        content: []
      }
    };
  },
  mounted() {
    let {id} = this.$route.query;
    this.handleOnload(id);
  },
  methods: {
    handleOnload(id) {
      this.$cloud.get("block/detail", {id}).then(data => {
        this.formData = data
      })
    },
    handleAddItem() {
      this.formData.content.push({
        key: this.handleInitial(),
        label: '项名称',
        type: '',
        isadd: true,
        level: 1,
        children: [{
          key: this.handleInitial(),
          equation: '',
          value: '',
          isadd: false,
          default: '0',
          relevancy: '1',
          award: 1,
          label: '',
          type: 1,
        }]
      })
    },
    /**
     * 处理初始值
     * @returns {string}
     */
    handleInitial() {
      let k = this.formData.initial++;
      return `Q${k}`
    },
    onSubmit() {
      this.$cloud.post("block/edit", this.formData).then(data => {
        this.$message.success('更新成功!')
        setTimeout(()=>{
          this.$cloud.go('/pages/model/model')
        },200)
      })
    }
  },
};
</script>
<style lang="scss"></style>