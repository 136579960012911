<template>
  <div class="regular-item">
    <div class="flex-1 regular-som " v-if="hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) ">
      <el-form-item label="#码">
        <el-input v-model="item.key" readonly></el-input>
      </el-form-item>
      <el-form-item label="名称类型">
        <el-radio-group v-model="item.relevancy">
          <el-radio label="1">固定名称</el-radio>
          <el-radio label="2">关联名称</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分项名称">
        <el-input v-model="item.label"></el-input>
      </el-form-item>
      <el-form-item label="分项名称">
        自动关联年级设置名称
      </el-form-item>
      <el-form-item label="选择类型">
        <el-select v-model="item.type" placeholder="请选择类型">
          <el-option :label="item.label" :value="item.value" v-for="(item,index) in typeOptions"
                     :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="计算公式" v-if="item.type===5">
        <el-input v-model="item.equation"></el-input>
      </el-form-item>
      <el-form-item label="关联奖项" v-if="item.type===6">
        <el-radio-group v-model="item.award">
          <el-radio label="1">音乐</el-radio>
          <el-radio label="2">体育</el-radio>
          <el-radio label="3">美术</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="绑定职务">
        <el-select v-model="item.subject_id" placeholder="请选择活动区域">
          <el-option :label="item.name" :value="item.id" v-for="(item,index) in subject"
                     :key="index"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="生成默认">
        <el-radio-group v-model="item.default">
          <el-radio label="1">生成</el-radio>
          <el-radio label="0">不生成</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleDelItem">删除项</el-button>
      </el-form-item>
    </div>
    <div v-else class="flex flex-wrap content-center">
      <div class="w-32 regular-title">

        <div class="regular-label">
          {{ item.evaluate ? '评|' : '' }}
          {{ item.star ? '星|' : '' }}
          {{ item.label }}
          <el-dropdown @command="handleCommand">
            <el-button type="text" icon="el-icon-more"></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="fixName">修改名称</el-dropdown-item>
              <el-dropdown-item command="setEvaluate" v-if="!parent">设置为评价</el-dropdown-item>
              <el-dropdown-item command="setStar" v-if="!parent">设置为打星</el-dropdown-item>
              <el-dropdown-item command="addBrother">添加同级</el-dropdown-item>
              <el-dropdown-item command="addSon" :disabled="!(this.level<3) || !item.isadd">添加子集</el-dropdown-item>
              <el-dropdown-item command="addItem">添加分项</el-dropdown-item>
              <el-dropdown-item command="delItem" divided>删除本级</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="regular-children flex-1">
        <RegularItem
            v-for="(child,index) in item.children"
            :key="child.path"
            :level="newLevel"
            :parent="item"
            :is-nest="true"
            :index="index"
            :initial="initial"
            :subject="subject"
            :item="child"
            class="nest-regular"
        />
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "RegularItem",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {},
  /**
   * 组建属性
   * @type {Object}
   */
  props: {
    parent: {
      type: [Object, String, Array]
    },
    index: {
      type: Number,
    },
    level: {
      type: Number,
      default: 1
    },
    item: {
      type: [Array, Object]
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    initial: {
      type: Function
    },
    subject: {
      type: Array
    }
  },
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    this.onlyOneChild = null;
    return {
      formData: {
        resource: 1
      },
      typeOptions: [{
        label: '打星',
        value: 1
      }, {
        label: '打分',
        value: 2
      }, {
        label: '选级',
        value: 3
      }, {
        label: '填值',
        value: 4
      }, {
        label: '公式',
        value: 5
      }, {
        label: '关联',
        value: 6
      }]
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    newLevel() {
      return Number(this.level) + 1;
    },
    isParent() {
      if (this.parent) {
        return true
      }
      return false
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {

  },
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {

  },
  /**
   * 页面调用函数
   * @type {Object}
   */

  methods: {
    handleCommand(command) {
      switch (command) {
        case "fixName":
          this.handleEditName();
          break;
        case "setEvaluate":
          this.handleSetEvaluate();
          break;
        case "setStar":
          this.handleSetStar();
          break;

        case "addBrother":
          this.handleAddBrother();
          break;
        case "addSon":
          this.handleAddSon();
          break;
        case "addItem":
          this.handleAddItem()
          break;
        case "delItem":
          this.handleDelItem();
          break;
      }
    },
    /**
     * 设置评价
     */
    handleSetEvaluate() {
      this.$nextTick(() => {
        this.item.evaluate = !this.item.evaluate;
      })
    },
    /**
     * 设置评星
     */
    handleSetStar() {
      this.$nextTick(() => {
        this.item.star = !this.item.star;
      })
    },
    handleEditName(data) {
      this.$prompt('设置名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        this.item.label = value;
        this.$message({
          type: 'success',
          message: '设置成功!'
        });
      }).catch(() => {

      });
    },
    /**
     * 删除集
     */
    handleDelItem() {
      this.$confirm('你确定删除该项吗？如果存在子集将一同删除！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("执行了删除本机")
        if (this.$parent.item.children.length > 1) {
          this.$parent.item.children.splice(this.index, 1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        } else {
          this.$message({
            message: '必须保留有一个项',
            type: 'warning'
          });
        }
      }).catch(() => {

      });
    },
    /**
     * 添加兄弟
     */
    handleAddBrother() {
      if (this.$parent.AddBrother) {
        this.$parent.AddBrother({
          key: this.initial(),
          label: '项名称',
          type: '',
          isadd: true,
          level: 1,
          children: [{
            key: this.initial(),
            equation: '',
            value: '',
            isadd: false,
            default: '0',
            relevancy: '1',
            award: 1,
            label: '',
            type: 1,
          }]
        })
      } else {
        if (this.$parent.item) {
          this.$parent.item.children.push({
            key: this.initial(),
            label: '项名称',
            type: '',
            isadd: true,
            level: 1,
            children: [{
              key: this.$parent.initial(),
              equation: '',
              value: '',
              isadd: false,
              default: '0',
              relevancy: '1',
              award: 1,
              label: '',
              type: 1,
            }]
          })
        }
      }
    },
    /**
     * 添加子集
     */
    handleAddSon() {
      let children = JSON.parse(JSON.stringify(this.item.children));
      this.item.isadd = false,
          this.item.children = [{
            key: this.initial(),
            equation: '',
            isadd: true,
            value: '',
            default: '0',
            relevancy: '1',
            award: 1,
            label: '项名称',
            type: 1,
            children: children
          }]
    },
    /**
     * 给子集添加项
     */
    handleAddItem() {
      this.$nextTick(() => {
        this.item.children.push({
          key: this.initial(),
          equation: '',
          value: '',
          default: '0',
          relevancy: '1',
          award: 1,
          label: '',
          type: 1,
        })
      })
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        this.onlyOneChild = item;
        return true;
      });
      // When there is only one child router, the child router is displayed by default

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = {...parent, noShowingChildren: true};
        return true;
      }
      return false;
    },
  }
};
</script>
<style lang="scss" scoped>
.content-center {
  min-height: 30px;
}

.regular-som {
  min-height: 50px;
  border-bottom: 1px solid #0d47a1;
  padding: 15px;
}

.regular-title {
  border-bottom: 1px solid #0d47a1;
}

.regular-item.nest-regular:last-child {
  //& > .regular-som, .regular-title {
  //  border-bottom: 0;
  //}
}


.regular-title {
  border-right: 1px solid #0d47a1;
  position: relative;

  .regular-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; //垂直居中
    justify-content: center; //水平居中
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

}
</style>