<template>
  <div>
    <RegularItem
        class="regular-main"
        v-for="(item,index) in permission_list"
        :key="index"
        :item="item"
        :subject="subjectOptions"
        :initial="initial"
    />
  </div>
</template>
<script>
import RegularItem from "./RegularItem";

export default {
  name: "index",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {
    RegularItem
  },
  /**
   * 组建属性
   * @type {Object}
   */
  props: {
    value: {
      type: [Array, Object]
    },
    initial: {
      type: Function
    }
  },
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {
      subjectOptions: []
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    permission_list() {
      return this.value
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {

  },
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {
    this.handleGetsubject();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    AddBrother(data) {
      this.value.push(data)
    },
    handleGetsubject() {
      this.$cloud.post("subject/options").then(data => {
        this.subjectOptions = data;
      }).catch(error => {

      })
    },
  }
};
</script>
<style scoped>
.regular-main {
  margin-bottom: 20px;
  border: 1px solid #0d47a1;
}
</style>